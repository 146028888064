import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import Home from "./pages/Home";
import Checkout from './pages/Checkout';
import CheckoutComplete from './pages/CheckoutComplete';
import CreateAccount from './pages/CreateAccount';
import Login from './pages/Login';
import Membership from './pages/Membership';
import MembershipPayment from './pages/MembershipPayment';
import Session from './pages/Session';
import UserHome from './pages/UserHome';
import AdminHome from './pages/AdminHome';
import AdminVenueManagment from './pages/AdminVenueManagment';
import SessionManagement from "./pages/SessionManagement";
import Logout from "./pages/Logout";
import SessionList from "./pages/SessionList";

const AppRoutes = [
  {
    index: true,
    element: <Home />,
    private: false
  },
  {
    path: '/counter',
    element: <Counter />,
    private: true
  },
  {
    path: '/fetch-data',
    element: <FetchData />,
    private: true
  },
  {
    path: '/checkout',
    element: <Checkout />,
    private: false
  },
  {
    path: '/checkout-complete',
    element: <CheckoutComplete />
  },
  {
    path: '/create-account',
    element: <CreateAccount />,
    private: false
  },
  {
    path: '/login',
    element: <Login />,
    private: false
  },
  {
    path: '/logout',
    element: <Logout />,
    private: false
  },
  {
    path: '/membership',
    element: <Membership />,
    private: true
  },
  {
    path: '/membership-payment',
    element: <MembershipPayment />,
    private: true
  },
  {
    path: '/session',
    element: <Session />,
    private: true
  },
  {
    path: '/sessions',
    element: <SessionList />,
    private: true
  },
  {
    path: '/user-home',
    element: <UserHome />,
    private: true
  },
  {
    path: '/admin-home',
    element: <AdminHome />,
    private: true
  },
  {
    path: '/admin-venue-managment',
    element: <AdminVenueManagment/>,
    private: true
  },
  {
    path: 'admin-session-managment',
    element: <SessionManagement />,
    private: true
  }
];

export default AppRoutes;
