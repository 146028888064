import React from 'react';
import { Container, Typography, Paper } from '@mui/material';
import Grid from "@mui/material/Grid2";

const AdminHome = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>Admin Dashboard</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <Paper style={{ padding: '20px' }}>
            <Typography variant="h6" gutterBottom>Upcoming Sessions</Typography>
            <Typography variant="body1">Display upcoming sessions here</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Paper style={{ padding: '20px' }}>
            <Typography variant="h6" gutterBottom>New Members</Typography>
            <Typography variant="body1">Display new members here</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Paper style={{ padding: '20px' }}>
            <Typography variant="h6" gutterBottom>Member Counts</Typography>
            <Typography variant="body1">Display member counts here </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Paper style={{ padding: '20px' }}>
            <Typography variant="h6" gutterBottom>Session Bookings Summary</Typography>
            <Typography variant="body1">Display session bookings summary here </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminHome;
