import { useNavigate } from 'react-router-dom';

const ConfirmEmail = () => {
    const navigate = useNavigate();

    navigate('/login');

    return null;
};

export default ConfirmEmail;
