import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const VenueInformation = () => {
    return (
        <Card sx={{ display: 'flex', alignItems: 'center', mb: 2,
            borderRadius: 3, // Rounded corners
            boxShadow: 3, }}>

            <CardContent>
            <img src="/img/venueLogos/insight-gym-logo.jpg" alt="Insight Gym Logo"
                 style={{ margin: '4px 25px', display: "flex",
                     justifyContent: "flex-end",
                     width: "300px",
                     aspectRatio: "2/1",
                     objectFit: "cover",
                     objectPosition : "center center"}}/>
            </CardContent>
            <CardContent>
                <h2>Insight Gym</h2>
                <p></p>
                <p>Phone : </p>
                <p>Email : </p>
                <p>Website : </p>

            </CardContent>
        </Card>
    );
}

export default VenueInformation;
