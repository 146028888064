import React, {useEffect, useState} from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element }) =>  {
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchToken = async () => {
      const token = await window.cookieStore.get('wh-jwt');
      setToken(token);
      setLoading(false);
    };

    fetchToken();
  }, []);

  if (loading) {
    return <div id="overlay">Loading...</div>;
  }

  return token ? element : <Navigate to="/login" />;
};

export default PrivateRoute;
