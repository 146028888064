import React, {useState} from 'react';
import {Button, TextField, Typography, Box, IconButton} from '@mui/material';
import {LocalizationProvider, TimePicker, DatePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import config from '../config';
import Grid from "@mui/material/Grid2";

const baseUrl = config.apiBaseUrl;

const AddSession = (props) => {
    const {handleClose, Values} = props;
    const [sessions, setSessions] = useState([]);
    const [sessionName, setSessionName] = useState('');
    const [sessionDate, setSessionDate] = useState(null);
    const [sessionTime, setSessionTime] = useState(dayjs().add(1, 'hour'));
    const [sessionSeats, setSessionSeats] = useState(1);
    const [sessionLength, setSessionLength] = useState(1);
    const [sessionTokenCost, setSessionTokenCost] = useState(1);

    const handleAddSession = () => {
        if (sessions.length >= 5) {
            alert('You can only add up to 5 sessions at a time.');
            return;
        }

        const newSession = {
            name: sessionName,
            date: sessionDate,
            time: sessionTime || dayjs().add(1, 'hour'),
            length: sessionLength,
            seats: sessionSeats,
            tokenCost: sessionTokenCost
        };

        setSessions([...sessions, newSession]);
        setSessionName('');
        setSessionDate(null);
        setSessionTime(dayjs().add(1, 'hour'));
        setSessionLength(1);
        setSessionSeats(1);
        setSessionTokenCost(1);
    };

    const handleRemoveSession = (index) => {
        const updatedSessions = sessions.filter((_, i) => i !== index);
        setSessions(updatedSessions);
    };

    const handleSubmit = () => {
        const sessionData =  sessions.map(session => ({
                startDate: `${session.date.format('YYYY-MM-DD')}T${session.time.format('HH:mm:00')}Z`,
                availableSeats: session.seats,
                numberOfMinuets: session.length * 60,
                name: session.name,
                tokenCost: session.tokenCost,
            }));

        fetch(`${baseUrl}/api/Sessions/AddSession/${Values.venue.key}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(sessionData),
        })
            .then(response => {
                if (response.ok) {
                    alert('Sessions added successfully');
                    handleClose();
                } else {
                    alert('Failed to add sessions');
                }
            })
            .catch(error => {
                console.error('Error adding sessions', error);
                alert('Failed to add sessions');
            });
    };


    return (
        <Box>
            <Typography variant="h6" component="h2">Add Session</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Session Date"
                    value={sessionDate}
                    onChange={(newValue) => setSessionDate(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal"/>}
                />
                <TimePicker
                    label="Session Start Time"
                    value={sessionTime}
                    onChange={(newValue) => setSessionTime(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal"/>}
                    ampm={false}
                />
            </LocalizationProvider>
            <TextField
                label="Session Name"
                value={sessionName}
                onChange={(e) => setSessionName(e.target.value)}
                fullWidth
                margin="normal"
            />

            <TextField
                label="Session Length (hours)"
                type="number"
                value={sessionLength}
                onChange={(e) => setSessionLength(e.target.value)}
                fullWidth
                margin="normal"
            />
            <TextField
                label="Available Seats for Session"
                type="number"
                value={sessionSeats}
                onChange={(e) => setSessionSeats(e.target.value)}
                fullWidth
                margin="normal"
            />
            <TextField
                label="session Token Cost"
                type="number"
                value={sessionTokenCost}
                onChange={(e) => setSessionTokenCost(e.target.value)}
                fullWidth
                margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleAddSession} sx={{mt: 2, ml: 2}}>
                Add Session
            </Button>
            <Button variant="contained" color="success" onClick={handleSubmit} sx={{mt: 2, ml: 2}}>
                Submit All Sessions
            </Button>
            <Grid container spacing={2} sx={{mt: 2}}>
                {sessions.map((session, index) => (
                    <Grid item xs={12} key={index}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" p={2}
                             border="1px solid #ccc" borderRadius="4px">
                            <Typography>{`${session.name} - ${session.date.format('YYYY-MM-DD')} ${session.time.format('HH:mm')} (${session.length} hours)`}</Typography>
                            <Typography>Seats : {session.seats}</Typography>
                            <IconButton onClick={() => handleRemoveSession(index)}>
                                <DeleteIcon/>
                            </IconButton>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default AddSession;
