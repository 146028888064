import React, { useState } from 'react';
import { TextField, Button, Alert, Box, Typography } from '@mui/material';
import config from "../config";

const baseUrl = config.apiBaseUrl;

const AddToken = (props) => {
  const { handleClose, Values } = props;
  const [token, setToken] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [newTokenValue, setNewTokenValue] = useState(0);

  const handleSubmit = (event) => {
    event.preventDefault();
    setMessage('');
    setError('');

    const formData = {
      tokenQty: parseInt(token, 10),
      userId: Values.user.userId,
      venueKey :Values.venue.key,
    };

    fetch(`${baseUrl}/api/users/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to add token');
        }
      })
      .then((data) => {
        setNewTokenValue(data.tokens);
        setMessage('Token added successfully');
        handleClose();
      })
      .catch((error) => {
        setError('Failed to add token');
      });
  };

  return (
      <Box sx={{ p: 2, border: '1px solid #ccc', borderRadius: '4px' }}>
        <Typography variant="h6">Add Token for User</Typography>
          <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              <Typography variant="h6">{Values.user.email}</Typography>
          </Box>
          <form onSubmit={handleSubmit}>
          <TextField
            id="token"
            label="Token"
            type="number"
            value={token}
            onChange={(e) => setToken(e.target.value)}
            fullWidth
            margin="normal"
            variant="standard"
            required
          />
          <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
            Add Token
          </Button>
          <Button variant="contained" color="secondary" sx={{ mt: 2, ml: 2 }} onClick={()=>handleClose(newTokenValue)}>
            Close
          </Button>
        </form>
        {message && <Alert severity="success" sx={{ mt: 2 }}>{message}</Alert>}
        {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
      </Box>
  );
};

export default AddToken;
