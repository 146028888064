import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import Helper from "../../helpers";
import {useEffect, useState} from "react";

const mainListItems = [
    { text: 'Home', icon: <HomeRoundedIcon />, path: '/dashboard' },
    { text: 'My Sessions', icon: <AnalyticsRoundedIcon />, path: '/dashboard/sessions?selected=mysessions' },
    { text: 'Admin Sessions', icon: <SupervisorAccountIcon />, path: '/dashboard/adminSession', IsAdminRequired: true },
    // { text: 'Membership', icon: <PeopleRoundedIcon />, path: '/dashboard/membership' },
    { text: 'Payment Provider Links', icon: <PeopleRoundedIcon />, path: '/dashboard/paymentproviderlinks' , IsAdminRequired: true},
    //{ text: 'Tasks', icon: <AssignmentRoundedIcon />, path: '/dashboard/tasks' },
];

const secondaryListItems = [
    // { text: 'Settings', icon: <SettingsRoundedIcon />, path: '/dashboard/settings' },
    // { text: 'About', icon: <InfoRoundedIcon />, path: '/dashboard/about' },
    // { text: 'Feedback', icon: <HelpRoundedIcon />, path: '/dashboard/feedback' },
    { text: 'Logout', icon: <LogoutIcon />, path: '/logout' },
];

export default function MenuContent() {
    const [isAdmin, setIsAdmin] = useState(false)

    useEffect(() => {
        const getUserInformation = async () => {
            const isAdmin = await Helper.userIsAdmin();
            setIsAdmin(isAdmin);
        }
        getUserInformation();
    }, []);

    return (
        <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
            <List dense>
                {mainListItems.map((item, index) => (
                    item.IsAdminRequired && !isAdmin ? null :
                    <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton selected={index === 0} component={Link} to={item.path}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>

            <List dense>
                {secondaryListItems.map((item, index) => (
                    <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton component={Link} to={item.path}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Stack>
    );
}
