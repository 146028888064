import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box, Button, Collapse, CssBaseline } from '@mui/material';
import { Link } from 'react-router-dom';
import { Home, AccountCircle, ExitToApp, Menu as MenuIcon } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import TokenCount from "./ToeknCount";
import './NavMenu.css';

const drawerWidth = 240;

const NavMenu = () => {
    const [collapsed, setCollapsed] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const theme = useTheme();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        // Check if the user is logged in
        const checkLoginStatus = async () => {
            const email = await window.cookieStore.get('wh.user.email');
            setIsLoggedIn(!!email);
        };

        checkLoginStatus();
    }, []);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    };

    return (
        <>
            <CssBaseline />
            <AppBar
                position="static"
                color="default"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    transition: theme.transitions.create(['width', 'margin'], {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
                }}
            >
                <Toolbar>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                        <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            WorldsHighStreet
                        </Typography>
                    </Box>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleNavbar}>
                        <MenuIcon />
                    </IconButton>
                    <Collapse in={!collapsed} timeout="auto" unmountOnExit>
                        <Box sx={{ display: 'flex', flexDirection: isMobileOrTablet ? 'column' : 'row' }}>
                            <Button component={Link} to="/dashboard" color="inherit" startIcon={<Home />}>
                                Home
                            </Button>
                            {isLoggedIn && (
                                <>
                                    <Button component={Link} to="/dashboard/sessions" color="inherit" startIcon={<AccountCircle />}>
                                        Sessions
                                    </Button>
                                    {/*<Button component={Link} to="/dashboard/membership" color="inherit" startIcon={<Settings />}>*/}
                                    {/*    Membership*/}
                                    {/*</Button>*/}
                                    <Button component={Link} to="/logout" color="inherit" startIcon={<ExitToApp />}>
                                        Logout
                                    </Button>
                                </>
                            )}
                        </Box>
                    </Collapse>
                    <TokenCount />
                </Toolbar>
            </AppBar>
        </>
    );
};

export default NavMenu;
