import React, { useState, useEffect } from 'react';
import { TextField, Button, Alert, Box, Typography, List, ListItem } from '@mui/material';
import config from '../config';

const baseUrl = config.apiBaseUrl;

const ManageSession = (props) => {
  const { handleClose, Values } = props;
  const [sessionName, setSessionName] = useState('');
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch session details
    fetch(`${baseUrl}/api/Sessions/${Values.sessionId}`)
      .then(response => response.json())
      .then(data => {
        setSessionName(data.name);
        setUsers(data.users);
      })
      .catch(error => {
        console.error('Error fetching session details:', error);
        setError('Failed to fetch session details');
      });
  }, [Values.sessionId]);

  const handleUpdateSession = () => {
    setMessage('');
    setError('');

    const updatedSession = {
      name: sessionName,
    };

    fetch(`${baseUrl}/api/Sessions/${Values.sessionId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(updatedSession),
    })
      .then(response => {
        if (response.ok) {
          setMessage('Session updated successfully');
        } else {
          throw new Error('Failed to update session');
        }
      })
      .catch(error => {
        console.error('Error updating session:', error);
        setError('Failed to update session');
      });
  };

  const handleCancelSession = () => {
    setMessage('');
    setError('');

    fetch(`${baseUrl}/api/Sessions/${Values.sessionId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then(response => {
        if (response.ok) {
          setMessage('Session cancelled successfully');
          handleClose();
        } else {
          throw new Error('Failed to cancel session');
        }
      })
      .catch(error => {
        console.error('Error cancelling session:', error);
        setError('Failed to cancel session');
      });
  };

  return (
    <Box sx={{ p: 2, border: '1px solid #ccc', borderRadius: '4px' }}>
      <Typography variant="h6">Manage Session</Typography>
      <TextField
        label="Session Name"
        value={sessionName}
        onChange={(e) => setSessionName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button variant="contained" color="primary" onClick={handleUpdateSession} sx={{ mt: 2 }}>
        Update Session
      </Button>
      <Button variant="contained" color="secondary" onClick={handleCancelSession} sx={{ mt: 2, ml: 2 }}>
        Cancel Session
      </Button>
      <Typography variant="h6" sx={{ mt: 4 }}>Users in Session</Typography>
      <List>
        {users.map((user, index) => (
          <ListItem key={index}>
            <Typography>{user.name}</Typography>
          </ListItem>
        ))}
      </List>
      {message && <Alert severity="success" sx={{ mt: 2 }}>{message}</Alert>}
      {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
    </Box>
  );
};

export default ManageSession;
