const Helper ={
    parseJwt : parseJwt,
    getAdminVenues : getAdminVenues,
    userIsAdmin : userIsAdmin,
    getUserId : getUserId,
    getUserVenueId : getUserVenueId
};

function parseJwt(token) {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    } catch (e) {
        console.error('Invalid token', e);
        return null;
    }
}

async function getAdminVenues(setCurrentVenueKey,setVenueKeyOptions) {
    const token = await window.cookieStore.get('wh-jwt');
    let cookie = Helper.parseJwt(token.value);
    let admin_venues = cookie.admin_venue;

    if (Array.isArray(admin_venues) && admin_venues.length > 0) {
        setCurrentVenueKey(admin_venues[0]);
        setVenueKeyOptions(admin_venues);
    } else if (typeof admin_venues === 'string') {
        setCurrentVenueKey(admin_venues);
        let venues = [];
        venues.push(admin_venues);
        setVenueKeyOptions(venues);
    }
};

async function userIsAdmin() {
    const token = await window.cookieStore.get('wh-jwt');
    let cookie = Helper.parseJwt(token.value);
    let admin_venues = cookie.admin_venue;

    return (Array.isArray(admin_venues) && admin_venues.length > 0) || typeof admin_venues === 'string';

};
async function getUserId() {
    const token = await window.cookieStore.get('wh-jwt');
    let cookie = Helper.parseJwt(token.value);
    return cookie.sub;
}

async function getUserVenueId() {
    const token = await window.cookieStore.get('wh-jwt');
    let cookie = Helper.parseJwt(token.value);
    return cookie.venue;
}



export default Helper;
