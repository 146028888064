import React, { useState, useEffect } from 'react';
import { CircularProgress, Alert } from '@mui/material';
import config from "../config";
import NextSessionContent from "./NextSessionContent";
const baseUrl = config.apiBaseUrl;

const NextUserSession = ({ userId }) => {
  const [nextSession, setNextSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNextSession = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/sessions/user/next/${userId}`,{
            method: 'GET',
            credentials: 'include'
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        if(response.status === 204){
            setNextSession(null);
            return;
        }
        const data = await response.json();

        setNextSession(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchNextSession();
  }, [userId]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">Error: {error.message}</Alert>;
  }

  return (
        <NextSessionContent nextSession={nextSession} />
  );
};

export default NextUserSession;
