import React, { useEffect, useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Tabs, Tab } from '@mui/material';
import config from "../config";
import SessionView from "../components/SessionView";
import Helper from "../helpers";
import { format, parseISO } from 'date-fns';

const baseUrl = config.apiBaseUrl;

const SessionAdminViewing = () => {
    const [sessions, setSessions] = useState([]);
    const [groupedSessions, setGroupedSessions] = useState({});
    const [loading, setLoading] = useState(true);
    const [currentVenueKey, setCurrentVenueKey] = useState("");
    const [venueKeyOptions, setVenueKeyOptions] = useState([]);
    const [selectedDate, setSelectedDate] = useState("");

    useEffect(() => {
        const fetchSessions = async () => {
            try {
                const from = new Date().toISOString();
                const to = new Date(new Date().setDate(new Date().getDate() + 9)).toISOString();
                const response = await fetch(`${baseUrl}/api/sessions/available/${currentVenueKey}/${from}/${to}`, {
                    method: 'GET',
                    credentials: 'include'
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setSessions(data);

                // Group sessions by date and add AllBooked section
                const grouped = data.reduce((acc, session) => {
                    const date = session.startDate.split('T')[0];
                    if (!acc[date]) {
                        acc[date] = [];
                    }
                    acc[date].push(session);

                    if (session.users && session.users.length > 0) {
                        if (!acc['AllBooked']) {
                            acc['AllBooked'] = [];
                        }
                        acc['AllBooked'].push(session);
                    }
                    return acc;
                }, {});
                setGroupedSessions(grouped);
                setSelectedDate('AllBooked');
                setLoading(false);
            } catch (error) {
                console.error('Error fetching sessions', error);
            }
        };

        const initialize = async () => {
            await Helper.getAdminVenues(setCurrentVenueKey, setVenueKeyOptions);
            if (currentVenueKey) {
                fetchSessions();
            }
        }
        initialize();
    }, [currentVenueKey]);

    const handleChange = (event, newValue) => {
        setSelectedDate(newValue);
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    const sortedDates = ['AllBooked', ...Object.keys(groupedSessions).filter(date => date !== 'AllBooked')];

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h4" gutterBottom>
                Admin Session List ({sessions.length} {venueKeyOptions.length})
            </Typography>
            <Tabs
                value={selectedDate}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="session tabs"
                sx={{
                    mb: 2,
                    '& .MuiTabs-scrollButtons': {
                        '&.Mui-disabled': { opacity: 0.3 },
                    },
                }}
            >
                {sortedDates.map(date => (
                    <Tab
                        key={date}
                        label={
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography variant="body1">{date === 'AllBooked' ? 'All Booked' : format(parseISO(date), 'MMM dd')}</Typography>
                                {date !== 'AllBooked' && <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>{format(parseISO(date), 'eeee')}</Typography>}
                            </Box>
                        }
                        value={date}
                        sx={{ minWidth: '100px' }}
                    />
                ))}
            </Tabs>
            {groupedSessions[selectedDate] && groupedSessions[selectedDate].length === 0 ? (
                <Typography variant="h6" gutterBottom>No sessions available</Typography>
            ) : (
                groupedSessions[selectedDate].map((session) => (
                    <Box key={session.id} sx={{ mb: 2 }}>
                        <SessionView session={session} AdminView={true}/>
                        <Typography variant="h6" gutterBottom>Attendees:</Typography>
                        <List>
                            {session.users && session.users.length > 0 ? session.users.map((user) => (
                                <ListItem key={user.userId}>
                                    <ListItemText primary={user.name} />
                                </ListItem>
                            )) : null}
                        </List>
                    </Box>
                ))
            )}
        </Box>
    );
};

export default SessionAdminViewing;
