import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import config from "../config";
const baseUrl = config.apiBaseUrl;

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${baseUrl}/api/users/signout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    window.cookieStore.delete("wh-jwt");
                    navigate('/login');
                } else {
                    console.error('Failed to log out');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [navigate]);

    return null;
};

export default Logout;
