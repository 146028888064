import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import Sidebar from './Sidebar';
import { Route, Routes } from 'react-router-dom';
import DashboardHome from "./compenents/DashhboardHome";
import SessionAdminViewing from "../pages/SessionAdminViewing";
import Membership from "../pages/Membership";
import SessionList from "../pages/SessionList";
import PaymentProviderLinks from "../components/PaymentProviderLinks";
import VenueInformation from "../components/VenueInformation";

const Dashboard = () => {
    return (
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Sidebar />
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        p: 3,
                        width: { sm: `calc(100% - 240px)` },
                    }}
                >

                        <VenueInformation />

                    {/*<Toolbar />*/}
                    <Routes>
                        <Route path="/" element={<DashboardHome />} />
                        <Route path="/sessions"  element={<SessionList />} />
                        <Route path="/adminSession"  element={<SessionAdminViewing />} />
                        <Route path="/membership" element={<Membership />} />
                        <Route path="/paymentproviderlinks" element={<PaymentProviderLinks />} />
                        {/* Add more routes as needed */}
                    </Routes>
                </Box>
            </Box>
    );
};

export default Dashboard;
