import { Button, Card, CardContent, CardMedia, Fab, List, ListItem, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { format, parseISO, differenceInHours } from "date-fns";
import React, {useEffect, useState} from "react";
import BookSession from "./BookSession";
import PopupModelWrapper from "./PopupModelWrapper";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import ConfirmCancelSession from "./ConfirmCancelSession";
import CallIcon from '@mui/icons-material/Call';
import ManageSession from "./ManageSession";
import Helper from "../helpers";

const SessionView = (props) => {
    const { session, handleOpen, AdminView } = props;

    const [isAdmin, setIsAdmin] = useState(false);
    const [openAddBookingModal, setOpenAddBookingModal] = useState(false);
    const [openCancelBookingModal, setOpenCancelBookingModal] = useState(false);
    const [openManageSessionModal, setOpenManageSessionModal] = useState(false);

    const handleCloseAddBookingModal = () => {
        setOpenAddBookingModal(false);
    }
    const handleCloseCancelBookingModal = () => {
        setOpenCancelBookingModal(false);
    }
    const handleCloseManageSessionModal = () => {
        setOpenManageSessionModal(false);
    }

    useEffect(() => {
        const checkAdmin = async () => {
            const admin = await Helper.userIsAdmin();
            setIsAdmin(admin);
        };
        checkAdmin();
    }, []);

    //const genericImageUrl = process.env.PUBLIC_URL + "/img/sessionGenerics/personal_session.jpeg";
    const genericImageUrl = process.env.PUBLIC_URL + "/img/insight_gym_long.jpg";

    return (
        <>
            <Card
                key={session.sessionId}
                sx={{
                    mb: 2,
                    borderRadius: 3, // Rounded corners
                    boxShadow: 3, // Subtle shadow
                    overflow: "hidden", // Hide image overflow for clean edges
                }}
            >
                <CardContent>
                    <Grid container spacing={2} alignItems="center">
                        {/* Left Column: Session Details */}
                        <Grid item xs={12} sm={4}>
                            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                {session.title}
                            </Typography>
                            <Fab variant="extended" size="medium" color="success" disableRipple={true} disableFocusRipple={true}>
                                <AccessTimeIcon sx={{ mr: 1 }} />
                                {format(parseISO(session.startDate), "MMM dd hh:mm")}
                            </Fab>
                            <Typography variant="body2" color="text.secondary">
                                Places Taken: {session.seatsTaken} / {session.availableSeats}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Length: {session.numberOfMinuets} minutes
                            </Typography>
                        </Grid>

                        {/* Middle Column: Action Buttons */}
                        <Grid item xs={12} sm={4} sx={{ textAlign: "center" }} offset={{ md: 'auto' }}>
                            <List aria-label="actions">
                                {(!AdminView && session.sessionBookText !== "Booked") && (
                                    <ListItem>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={(session.availableSeats - session.seatsTaken) === 0 || differenceInHours(new Date(session.startDate), new Date()) < 24}
                                            onClick={() => setOpenAddBookingModal(true)}
                                            sx={{
                                                borderRadius: 20, // Rounded button
                                                px: 4, // Increased padding
                                                fontWeight: "bold",
                                            }}
                                            endIcon={differenceInHours(new Date(session.startDate), new Date()) < 24 ? <CallIcon /> : <SendIcon />}
                                        >
                                            {(session.availableSeats - session.seatsTaken) === 0
                                                ? "Full"
                                                : differenceInHours(new Date(session.startDate), new Date()) < 24
                                                    ? "Call to Book"
                                                    : session.sessionBookText}
                                        </Button>
                                    </ListItem>
                                )}
                                {(!AdminView && session.sessionBookText === "Booked") && (
                                    <ListItem>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            disabled={session.availableSeats === 0}
                                            onClick={() => setOpenCancelBookingModal(true)}
                                            sx={{
                                                borderRadius: 20, // Rounded button
                                                px: 4, // Increased padding
                                                fontWeight: "bold",
                                            }}
                                            endIcon={<CancelIcon />}
                                        >
                                            Cancel Session
                                        </Button>
                                    </ListItem>
                                )}
                                {isAdmin && (
                                    <ListItem>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size={"small"}
                                            onClick={() => handleOpen({ title: session.name, userType: "AddToken" })}
                                            sx={{
                                                borderRadius: 20, // Rounded button
                                                px: 4, // Increased padding
                                                fontWeight: "bold",
                                            }}
                                            endIcon={<CancelIcon />}
                                        >
                                            Manage Session
                                        </Button>
                                    </ListItem>
                                )}
                            </List>
                        </Grid>

                        {/* Right Column: Session Image (Placeholder) */}
                        <Grid
                            item
                            xs={12}
                            sm={4}
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                width: "300px",
                                aspectRatio: "2/1",
                                objectFit: "cover",
                                objectPosition : "top center"
                            }}
                            offset={{ md: 'auto' }}
                        >
                            {session.imageUrl ? (
                                <CardMedia
                                    component="img"
                                    image={session.imageUrl}
                                    alt={session.title}
                                    sx={{ objectFit: "cover", width: "100%", maxHeight: "140px" }} // Cover the entire container with max height
                                />
                            ) : (
                                <CardMedia
                                    component="img"
                                    image={genericImageUrl}
                                    alt="Generic Session"
                                    sx={{ objectFit: "cover", width: "100%", maxHeight: "140px",filter: "grayscale(100%) opacity(0.5)" }} // Cover the entire container with max height
                                />
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <PopupModelWrapper isOpen={openAddBookingModal} handleClose={handleCloseAddBookingModal}
                ModelContent={BookSession}
                Values={{ title: session.title, sessionId: session.sessionId, sessionCost: session.sessionCost }} />
            <PopupModelWrapper isOpen={openCancelBookingModal} handleClose={handleCloseCancelBookingModal}
                ModelContent={ConfirmCancelSession}
                Values={{ title: session.title, sessionId: session.sessionId }} />
            <PopupModelWrapper isOpen={openManageSessionModal} handleClose={handleCloseManageSessionModal}
                ModelContent={ManageSession}
                Values={{ title: session.title, sessionId: session.sessionId, sessionDateTime : session.startDate}} />
        </>
    )
}

export default SessionView;
